<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">Notifications</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Alerts to pivotal moments</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
                    <div class="mb-1 breadcrumb-right">
                        <base-card-drop-down class="fs-4">
                            <template #title>
                                <i class="bi bi-plus" ></i> Options
                            </template>
                            <template #default>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-check-circle" ></i>
                                    Create JD from scratch
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-circle" ></i>
                                    Duplicate existing JD
                                </a>
                                <a class="dropdown-item" href="#" v-if="useExistingJD">
                                    <i class="bi bi-circle " ></i>
                                    Load existing JD
                                </a>
                            </template>
                        </base-card-drop-down>
                    </div>
                </div>
            </div>
            <div class="content-body">

                <reports-notification-list>
                </reports-notification-list>

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { useStore } from 'vuex';

import ReportsNotificationList from '@/components/reports/ReportsNotificationList.vue';

export default{
    components:{
        ReportsNotificationList
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);
    }
}
</script>

<style scoped>
.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;}


</style>
